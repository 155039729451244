import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PortfolioBanner from '../components/portfolio-banner/PortfolioBanner'
import SimpleText from '../components/simple-text/SimpleText'
import CarrerFilters from '../components/carrer-filters/CarrerFilters'
import JobOffertItem from '../components/job-offert/JobOffertItem'
import Pagination from '../components/pagination/Pagination'
import SEO from '../components/seo'

class NewsTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      filteredItems: [],
      position: '',
      city: '',
      type: '',
      visibleItems: 6,
      actualPage: 1,
    }
  }
  setFooterMargin() {
    document.querySelector('footer').style.marginTop = '-120px'
  }
  changePosition(value) {
    this.setState({ position: value })
  }
  changeCity(value) {
    this.setState({ city: value })
  }
  changeType(value) {
    this.setState({ type: value })
  }
  setPostsToState() {
    this.setState({
      items: this.props.data.allWordpressWpJob.edges,
      filteredItems: this.props.data.allWordpressWpJob.edges,
    })
  }

  async search() {
    let tempNews = this.state.items
    if (
      this.state.position == '' &&
      this.state.city == '' &&
      this.state.type == ''
    ) {
      await this.setPostsToState()
    } else {
      await this.setState({ filteredItems: [] })
      await tempNews
        .filter(item =>
          this.state.position != ''
            ? item.node.positions[0] == this.state.position
            : item
        )
        .filter(item =>
          this.state.city != '' ? item.node.cities[0] == this.state.city : item
        )
        .filter(item =>
          this.state.type != ''
            ? item.node.work_types[0] == this.state.type
            : item
        )
        .map(item => {
          this.setState({ filteredItems: [...this.state.filteredItems, item] })
        })
    }
  }
  componentDidMount() {
    this.setFooterMargin()
    this.setPostsToState()
  }
  changePage(index) {
    this.setState({ actualPage: index })
  }
  render() {
    const currentPage = this.props.data.wordpressPage

    const Items = data => {
      let n = 0
      let maxItems = data.data.visibleItems
      let actualPage = data.data.actualPage
      let items = data.data.filteredItems.map((item, index) => {
        if (
          n < maxItems &&
          index <= Math.round(maxItems * actualPage - 1) &&
          index >= Math.round(maxItems * actualPage - 1 - maxItems + 1)
        ) {
          n++
          return <JobOffertItem key={index} data={item.node} />
        }
      })
      return items
    }
    return (
      <Layout>
        <SEO
          title={
            currentPage.title +
            ' | Kuchnie na wymiar Śląsk, Małopolska | Meble kuchenne Kraków'
          }
        />
        <PortfolioBanner data={currentPage.featured_media} />
        <div className="take-up">
          <SimpleText text={currentPage.content} />
          <CarrerFilters
            positions={this.props.data.allWordpressWpPositions.edges}
            cities={this.props.data.allWordpressWpCities.edges}
            types={this.props.data.allWordpressWpWorkTypes.edges}
            changePosition={this.changePosition.bind(this)}
            changeCity={this.changeCity.bind(this)}
            changeType={this.changeType.bind(this)}
            search={this.search.bind(this)}
          />
          <section className="job-offerts">
            <div className="container">
              <div className="row">
                <div className="job-offerts-wrap ">
                  <Items data={this.state} />
                </div>
              </div>
            </div>
          </section>

          {this.state.filteredItems.length > this.state.visibleItems && (
            <Pagination
              newsLength={this.state.filteredItems.length}
              changePage={this.changePage.bind(this)}
              maxShowItems={this.state.visibleItems}
            />
          )}
        </div>
      </Layout>
    )
  }
}

export default NewsTemplate

export const pageQuery = graphql`
  query($id: String) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080, quality: 95) {
              srcSet
            }
          }
        }
      }
    }
    allWordpressWpJob(filter: { status: { eq: "publish" } }) {
      edges {
        node {
          post_title: title
          post_excerpt: excerpt
          post_name: slug
          positions
          cities
          work_types
        }
      }
    }

    allWordpressWpPositions {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    allWordpressWpCities {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    allWordpressWpWorkTypes {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
